import React from 'react'
import Navbar from '../components/Navbar'
import Socials from '../components/Socials'
function About() {


  return (
    <div className="home-page">
      <img className="logo" src="images\logospace.png"></img>
      <Navbar></Navbar>
      <article className='about-article'>
        <p className="about-text">
          Veronica Peterson is a dedicated visual artist studying at Laguna College of Art + Design in pursuit of a career designing meaningful experiences in entertainment.
        </p>
        <p className="about-text">
          Her artwork is an extension of her love for childhood — particularly, the symbolic nature of toys. Even her works that do not feature toys, or do not explicitly connect to toys thematically, still feature themes of playing; pattern-seeking, problem-solving.
        </p>
        <p className="about-text">
          She finds joy when she chooses to see the ordinarily mundane in an optimistic light, and she shares this joy with the world by means of design. She believes by experiencing life through drawing — we may become limitless.
        </p>
      </article>
      <img alt="" className='about-gallery-img' src='images/about-art2.png'></img>
    </div>
  )
}

export default About