import React from 'react'
import Navbar from '../components/Navbar'
function Contact() {
    return (
        <div className="home-page">
            <img className="logo" src="images\logospace.png"></img>
            <Navbar></Navbar>
            <div className='contact-container'>
                <div className='contact-left-container'>
                    <form className="email-form" action="https://formsubmit.co/veronica@rozeruki.art" method="POST">
                        <p>Name</p>
                        <input type="text" name="name" required/>

                        <p>Email</p>
                        <input type="email" name="email" required/>

                        <p>Subject</p>
                        <input type="text" name="_subject"/>

                        <p>Message</p>
                        <textarea type="text" name="message" required/>

                        <button type="submit">Send</button>
                    </form> 
                </div>
                <div className="contact-right-container" >
                    <h1 className="">veronica<wbr/>@<wbr/>rozeruki<wbr/>.art</h1>
                    <img className="contact-art" src="images\contact-art.png"></img>
                </div>
            </div>

            
        </div>
    )
}

export default Contact