import React, { useEffect, useState } from 'react'
import ImageList from "@mui/material/ImageList";
import ArtPiece from '../components/ArtPiece';
import Navbar from '../components/Navbar';
import { useParams, queryString, useLocation, useNavigate } from 'react-router-dom';
import OpenedImage from '../components/OpenedImage';
import galleries from '../galleries';


let galleriesMap = {
  "all": galleries.uncategorizedImages.concat(galleries.plushDollImages, galleries.illustrationImages),
  "plush-dolls": galleries.plushDollImages,
  "illustration": galleries.illustrationImages,
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Gallery = ({images}) => {
  let {gallery, } = useParams();
  let [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let [scrollbarWidth, setScrollbarWidth] = useState(0);
  const query = useQuery();
  const navigate = useNavigate();
  useEffect(()=>{
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth)
      if(query.get("image")){
        if(document.body.style.overflowY === "hidden") return;
        var scrollDiv = document.createElement("div");
        scrollDiv.className = "scrollbar-measure";
        document.body.appendChild(scrollDiv);
        
        // Get the scrollbar width
        var scrollbarWidth = window.innerWidth - document.body.clientWidth;
        setScrollbarWidth(scrollbarWidth);
        document.body.style.overflowY = "hidden";
        document.body.style.paddingRight = scrollbarWidth + "px";
        // Delete the div
        document.body.removeChild(scrollDiv);
      }
      else {
        document.body.style.overflowY = "scroll";
        document.body.style.position = "static";
        document.body.style.paddingRight = 0;
      }
    });
  },[query])

  useEffect(()=>{
    if(query.get("image")){
      if(document.body.style.overflowY === "hidden") return;
      var scrollDiv = document.createElement("div");
      scrollDiv.className = "scrollbar-measure";
      document.body.appendChild(scrollDiv);
      
      // Get the scrollbar width
      var scrollbarWidth = window.innerWidth - document.body.clientWidth;
      setScrollbarWidth(scrollbarWidth);
      document.body.style.overflowY = "hidden";
      document.body.style.paddingRight = scrollbarWidth + "px";
      // Delete the div
      document.body.removeChild(scrollDiv);
    }
    else {
      document.body.style.overflowY = "scroll";
      document.body.style.position = "static";
      document.body.style.paddingRight = 0;
    }
  }, [query])
  
  return (
    
    <div className='gallery'>
      <Navbar className="Navbar__menu">
      
      </Navbar>
      <ImageList sx={{ width: "100%", padding:"16px", boxSizing:"border-box", margin:"0px"}} cols={Math.ceil(3*windowWidth/2000)*2} rowHeight={.8*windowWidth/(2*Math.ceil(3*windowWidth/2000))} variant="quilted">
        {galleriesMap[gallery].map((image, index)=>{
          return <ArtPiece onClick={()=>navigate('?image='+index, {shallow:true})} key={index} id={image.source} title={image.title} description={image.description} rows={image.height} cols={Math.min(Math.ceil(3*windowWidth/2000)*2,image.width?image.width:2)}></ArtPiece>
        })}
      </ImageList>
        {query.get("image") && <OpenedImage onClick={()=>{}} style={{position:"fixed",top:"50vh", transform: "translate(0, -50%)"}} index={query.get("image")} galleryString={gallery} scrollbarWidth={scrollbarWidth} ></OpenedImage>}
    </div>
  
  )
}

export default Gallery