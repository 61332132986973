import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import galleries from '../galleries';

const galleriesMap = {
  "all": galleries.uncategorizedImages.concat(galleries.plushDollImages, galleries.illustrationImages),
  "plush-dolls": galleries.plushDollImages,
  "illustration": galleries.illustrationImages,
}

function OpenedImage({ galleryString, index, scrollbarWidth }) {
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.stopPropagation();

    const newIndex =
      (parseInt(index) + (event.currentTarget.dataset.direction === "next" ? 1 : -1) +
        galleriesMap[galleryString].length) %
        galleriesMap[galleryString].length;

    navigate(`?image=${newIndex}`, { shallow: true });
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        top: 0,
        left: 0,
        background: "rgb(1,1,1,.75)",
        zIndex: 1001,
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "scroll"
      }}
      onClick={() => navigate("")}
    >
      <button
        className="opened-image-button"
        style={{
          top:"50%",
          left:"0",
          transform:"translateY(-50%)",
        }}
        data-direction="prev"
        onClick={handleClick}
        text="<"
      >
        &lt;
      </button>
      <div style={{display:"flex", width:"75%", height:"100%", justifyContent:"center", alignItems:"center"}}>
        <div onClick={(e) => e.stopPropagation()} style={{ maxHeight: "90%", display: "flex", flexDirection: "column", alignItems: "center", marginY:"auto"}}>
          <div style={{minWidth:"60%", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"black"}}>
            <img
              alt=""
              style={{
                maxHeight: "80vh",
                maxWidth: "100%",
                zIndex: 1002,
                objectFit: "contain",
              }}
              src={
                process.env.PUBLIC_URL +
                "/images/" +
                galleriesMap[galleryString][index].source +
                ".png"
              }
            />
          </div>
          <div style={{ width:"100%", maxHeight:"10%", backgroundColor:"#ffcfd1", textAlign:"center", display:"flex", justifyContent:"center"}}>
            <h1 style={{marginBottom:0,fontSize:"25px"}}>{galleriesMap[galleryString][index].title}</h1>
          </div>
            <div style={{width:"100%"}}>
              <div id="description" style={{ display:"flex", width:"calc(100%-32px)", backgroundColor:"#ffcfd1", textAlign:"center", zIndex: -1, padding:"16px"}}>
                <h1 style={{flexGrow:1, width:0, fontSize:"25px", fontWeight:"normal", whiteSpace: "pre-line"}}>{galleriesMap[galleryString][index].description}</h1>
              </div>
              <div style={{ height:"20px"}}></div>
            </div>
        </div>
      </div>

      <button
        className="opened-image-button"
        style={{
          top:"50%",
          right:scrollbarWidth + "px",
          transform:"translateY(-50%)",
        }}
        data-direction="next"
        onClick={handleClick}
      >
        &gt;
      </button>
    </div>
  );
}

export default OpenedImage