const galleries = {
    uncategorizedImages: [
        
    ],
    plushDollImages: [
        {
            source: "plush-dolls/4Awawa-chan V1",
            title: "Awawa-chan V1",
            description: "A handmade, miniature plush doll inspired by the Kamio Japan character ‘Awawa-chan.’\n\nSewn by hand. Features machine embroidery details. Private commission. Approximately 5 cm (2 inches) in height.",
        },
        {
            source: "plush-dolls/5Yoguru-kun V2",
            title: "Yoguru-kun V2",
            description: "A handmade plush doll inspired by the San-x character ‘Yoguru-kun.’\n\nThis was initially a prototype of Yoguru-kun V1, but my friend liked it so much that she asked if I could give him a little jar and a spoon to hold!\n\nSewn by hand. Features machine embroidery details. Private commission.",
        },
        {
            source: "plush-dolls/6Awawa-chan V2",
            title: "Awawa-chan V2",
            description: "A handmade, miniature plush doll inspired by the Kamio Japan character ‘Awawa-chan.’\n\nSewn by hand. Features machine embroidery details. Private commission. Approximately 5 cm (2 inches) in height.",
        },
        {
            source: "plush-dolls/1Yoguru-kun V1",
            title: "Yoguru-kun V1",
            description: "A handmade plush doll recreation of a vintage San-x ‘Yoguru-kun’ keychain-sized plushie.\n\nThe customer sent me reference photos that informed much of the construction process. This involved multiple pattern revisions to ensure that the finished result would be an accurate representation.\n\nSewn by hand. Features machine embroidery details. Private commission.",
        },
        {
            source: "plush-dolls/2Bee",
            title: "Bee",
            description: "A handmade plush doll recreation of a vintage San-x ‘Bee’ keychain-sized plushie.\n\nThe customer sent me reference photos that informed much of the construction process. This involved multiple pattern revisions to ensure that the finished result would be an accurate representation.\n\nSewn by hand. Features machine embroidery details. Private commission.",
        },
        {
            source: "plush-dolls/3Purin-kun",
            title: "Purin-kun",
            description: "A handmade plush doll inspired by the San-x character ‘Purin-kun.’\n\nSewn by hand. Features machine embroidery details. Private commission.",
        },
        {
            source: "plush-dolls/7Beanie-Style Cats",
            title: "Beanie-Style Cats",
            description: "My own interpretations of the infamous ‘Beanie Babies.’ Wholly original design and pattern.\n\nSewn by hand. Featuring plastic safety eyes and noses. Sold.",
        },
        {
            source: "plush-dolls/8Shiawase Nyanko",
            title: "Shiawase Nyanko",
            description: "A handmade plush doll recreation of a vintage San-x ‘Shiawase Nyanko’ keychain-sized plushie.\n\nMade using materials similar to those used in the official plushie released by San-x in July 2001.\n\nThe customer sent me reference photos that informed much of the construction process. This involved multiple pattern revisions to ensure that the finished result would be an accurate representation.\n\nSewn by hand. Features machine embroidery details and plastic safety eyes. Private commission.",
        },
        {
            source: "plush-dolls/9Cro-cat-dile",
            title: "Cro-cat-dile",
            description: "A handmade plush doll based on a character that I designed (see ideation process on the bottom-half of the image). Wholly original design and pattern.\n\nSewn by hand. Featuring plastic safety eyes and noses. Not for sale.",
        },
    ],
    illustrationImages: [
        {
            source: "illustration/1Two Kittens",
            title: "Two Kittens",
            description: "You will find that I draw a lot of cats.",
        },
        {
            source: "illustration/2Rainbow Friends",
            title: "Rainbow Friends",
            description: "A series of characters I designed, inspired by the ‘平成レトロ’ (Heisei retro) aesthetic. I modeled each character with one of my friends in mind, curating each one to symbolize that person’s individual interests and personality, while simultaneously making them relatable to a general audience.",
        },
        
        {
            source: "illustration/4Cthulhu Potato",
            title: "Cthulhu Potato",
            description: "Influenced by the concept of ‘sympathetic magic’ — i.e., a belief in magic based on imitation or correspondence. Since a potato plant resembles a cephalopod, it may be considered an effective proxy. Lovecraft describes the Elder Things as possessing traits of both plants and animals. Considering that many of the Old Ones are hidden or trapped, it makes further sense that a potato might act as some Eldritch being.",
        },
        {
            source: "illustration/3Creature Design Visual Development",
            title: "Creature Design Visual Development",
            width: 1,
            height: 1,
            description: "Thumbnail explorations of what a plant-based creature might look like.",
        },
        {
            source: "illustration/5Creature Embryos",
            title: "Creature Embryos",
            width: 1,
            height: 1,
            description: "Alien fetal development designs, inspired by my biological research into the varying, novel systems that Earth’s organisms have evolved to reproduce.",
        },
        {
            source: "illustration/6_Girlfriend in a Coma_ Storyboard - Detail",
            title: "'Girlfriend in a Coma' Storyboard (Detail)",
            description: "A frame from a storyboard music video set to the song ‘Girlfriend in a Coma’ by The Smiths. The story follows two characters in conflict with each other, both represented through self-portrait. It is a retrospective on my experience growing up with anorexia nervosa.",
        },
        {
            source: "illustration/7Still Life",
            title: "Still Life",
            description: "I staged objects from my collection of small treasures and referenced this arrangement to create a digital painting.",
        },
        {
            source: "illustration/8Digital Plein Air",
            title: "Digital Plein Air",
            width: 2,
            height: 1,
            description: "A digital study for landscape class, made using a digital photograph from the application ‘MapCrunch’ as reference.",
        },
        {
            source: "illustration/9_Psiche Abbadonata_ Study",
            title: "'Psiche Abbandonata' Study",
            description: "An illustrated study of a statue by Pietro Tenerani that I did while I was in Florence, Italy.",
        },
        {
            source: "illustration/10_Dying Adonis_ Study",
            title: "'Dying Adonis' Study",
            description: "An illustrated study of a statue by Vincenzo de' Rossi that I did while I was in Florence, Italy.",
        },
    ]
}

galleries.all = galleries.uncategorizedImages.concat(galleries.plushDollImages, galleries.illustrationImages);

export default galleries;