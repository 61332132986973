import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
        <div>© Veronica Peterson 2024</div>
        <div>
            <a href='https://www.instagram.com/rozeruki/' target="_blank" rel="noreferrer"><img alt='instagram' src='/images/insta-logo.png'></img></a>
            <a href='https://www.artstation.com/rozeruki' target="_blank" rel="noreferrer"><img alt='artstation' src='/images/artstation-logo.png'></img></a>
            <a href='https://www.etsy.com/shop/Rozeruki' target="_blank" rel="noreferrer"><img alt='etsy' src='/images/etsy-logo.png'></img></a>
        </div>
    </div>
  )
}

export default Footer