import MenuItem from './MenuItem';

const Navbar = () => {
    
    return (
        <ul className="Navbar__menu">
            <li>
                <MenuItem title="home" link={process.env.PUBLIC_URL + "/home"}>
                </MenuItem>
            </li>
            <li>
                <MenuItem title="galleries" link={process.env.PUBLIC_URL + "/gallery/all"}>
                    <MenuItem title="illustration" link={process.env.PUBLIC_URL + "/gallery/illustration"}></MenuItem>
                    <MenuItem title="plushies" link={process.env.PUBLIC_URL + "/gallery/plush-dolls"}></MenuItem>
                </MenuItem>
            </li>
            <li>
                <MenuItem title="about" link={process.env.PUBLIC_URL + "/about"}>
                </MenuItem>
            </li>
            <li>
                <MenuItem title="contact" link={process.env.PUBLIC_URL + "/contact"}>
                </MenuItem>
            </li>
        </ul>
    );
};

export default Navbar;